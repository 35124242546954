import request from '@/common/http/request'

// 获取充值菜单
export function getRechargeList(data) {
    return request({
        url: '/setRecharge/rechargelist',
        method: 'post',
        data
    })
}
// 创建充值订单
export function createMemberRechargeOrder(data) {
    return request({
        url: '/memberRechargeOrder/create',
        method: 'post',
        data
    })
}

// 获取充值菜单
export function getRate(data) {
    return request({
        url: '/memberRechargeOrder/getRate',
        method: 'post',
        data
    })
}

// 创建USDT充值订单
export function createMemberUsdtRechargeOrder(data) {
    return request({
        url: '/memberRechargeOrder/usdtRechargeCreate',
        method: 'post',
        data
    })
}

// 取消充值订单
export function cancelUsdtDepositOrder(data) {
    return request({
        url: '/memberRechargeOrder/delUsdtRecharge',
        method: 'post',
        data
    })
}

// 用户填写hash地址
export function WriteUsdtDepositOrderHash(data) {
    return request({
        url: '/memberRechargeOrder/memberWriteHash',
        method: 'post',
        data
    })
}
// udtd教程
export function getUsdtTutorials(data) {
    return request({
        url: '/setRecharge/getUsdtTutorials',
        method: 'post',
        data
    })
}

// 取得最近的3小时内，待支付的usdt代收订单（多条只取最近一条）
export function getUsdtRechargeOrderByWaitPay(data) {
    return request({
        url: '/memberRechargeOrder/usdt/waitPay',
        method: 'post',
        data,
        isNoToastErr: true
    })
}

<template>
    <div class="popup" :class="classObj" v-if="isLogin">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <div class="header">
                    <div>{{ $t("MyProfile.Profile") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="user_content">
                    <div class="user_box">
                        <div class="vip_img" v-if="userInfo.levelDetailVo">
                            <img :src="userInfo.levelDetailVo.memberLevelIcon" alt="">
                        </div>
                        <div class="user_info">
                            <div class="avatar">
                                <img src="@/assets/person/avatar.png">
                            </div>
                            <div class="user">
                                <div class="phone">{{ userPhone }}</div>
                                <div>
                                    {{ $t("MyProfile.ID") }}: {{ userInfo.id }}
                                    <i class="iconfont icon-fuzhi" @click="copyText(userInfo.id)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="user_account">
                        <div>
                            <div>{{ $t("MyProfile.Join") }}</div>
                            <div>{{ registerTime }}</div>
                        </div>
                        <div>
                            <div>{{ $t("MyProfile.TotalBet") }}</div>
                            <div>
                                {{ CurrencyType }} {{ userInfo.grandTotalBouns || 0 }}
                            </div>
                        </div>
                        <div>
                            <div>{{ $t("MyProfile.TotalBonus") }}</div>
                            <div>
                                {{ CurrencyType }} {{ userInfo.grandTotalBetting || 0 }}
                            </div>
                        </div>
                    </div>
                    <div class="fav_games">
                        <div class="title">{{ $t("MyProfile.FavoriteGames") }}</div>
                        <van-list v-model="favGame.isLoading" :finished="!favGame.hasMore" loading-text=""
                            @load="getFavList" class="game_list" offset="50" :immediate-check=false
                            v-if="favGame.favList.length !== 0">
                            <div class="game_item" v-for="(item, index) in favGame.favList" :key="item.id">
                                <div class="game_img">
                                    <div class="game_mask">
                                        <div class="RTP">
                                            <span>RTP</span>
                                            <span>{{ item.rtp }}%</span>
                                        </div>
                                        <div class="game_center">
                                            <div class="name">{{ item.gameName }}</div>
                                            <div class="game_btn" @click.stop="onShowGamePopup(item)">
                                                <i class="iconfont icon-shipin"></i>
                                                <span>{{ $t("lang.Play") }}</span>
                                            </div>
                                        </div>
                                        <div class="game_collect" @click.stop="addCollect(item, index)">
                                            <i v-if="activeIds.includes(item.id)"
                                                class="iconAct iconfont icon-a-shoucangyishoucang"></i>
                                            <i v-else class="iconfont icon-a-shoucang_quxiaoshoucang"></i>
                                        </div>
                                    </div>
                                    <img :src="item.imageUrl" />
                                </div>
                                <div class="gameName">{{ item.gameName }}</div>
                            </div>
                        </van-list>
                        <div class="empty" v-else>
                            <img src="@/assets/common/empty.png" alt="" class="empty_img">
                            <div>{{ $t("lang.NoData") }}</div>
                        </div>
                        <div v-if="favGame.isLoading && favGame.favList.length" class="loading">
                            <a-spin>
                                <a-icon slot="indicator" type="loading" spin />
                            </a-spin>
                            {{ $t("lang.Loading") }}
                        </div>
                        <div v-if="!favGame.hasMore && favGame.favList.length" class="loading">
                            {{ $t("lang.NoMore") }}
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
        <gamePop :showPop="isShowGamePop" :info="gamePopInfo" :activeIds="activeIds"
            @activeIdsSplice="activeIds.splice($event, 1)" @activeIdsPush="activeIds.push($event)"
            @close="isShowGamePop = false" @onShowGamePopup="onShowGamePopup">
        </gamePop>
        <gamePopup ref="gamePopup" :params="gameParams"></gamePopup>
    </div>
</template>

<script>
import handleCopy from '@/utils/copy.js'
import { Toast } from 'vant'
import gamePop from '@/components/gamePopup/infoPopup'
import gamePopup from '@/components/gamePopup'
import { mapState } from 'vuex'
import {
    getGameCollect,
    getGameAllCollect,
    addFav,
    removeFav
} from '@/api/game'
export default {
    name: 'myProfile',
    components: {
        gamePop,
        gamePopup
    },
    data() {
        return {
            favGame: {
                favList: [],
                pageNo: 0,
                pageSize: 9,
                hasMore: true,
                isLoading: false
            },
            activeIds: [],
            gameParams: {
                id: '',
                gameInfoId: '',
                gamePlatformType: '',
                providerType: ''
            },
            isShowGamePop: false,
            gamePopInfo: {},
            registerTime: '',
            firstLoaded: true
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        userInfo() {
            return this.$store.getters.userInfo
        },
        userPhone() {
            const phone = this.userInfo && this.userInfo.phone
            return phone ? this.maskNumber(phone) : ''
        },
        show: {
            get() {
                return this.$store.getters.isShowMyProfilePopup
            },
            set(val) {
                return val
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.$store.dispatch('user/setIsShowMyProfilePopup', false)
        },
        open() {
            if (this.firstLoaded) {
                this.init()
            }
        },
        init() {
            this.getFavList()
            this.getGameFavs()
            if (this.userInfo) {
                this.registerTime = this.userInfo.registerTime.slice(0, -8)
            }
        },
        maskNumber(phoneNumber) {
            const str = phoneNumber.toString()
            if (str.length <= 4) {
                return str
            }
            const firstTwo = str.slice(0, 2)
            const lastTwo = str.slice(-2)
            return `${firstTwo}***${lastTwo}`
        },
        getFavList() {
            if (!this.favGame.hasMore && this.favGame.isLoading) { return }
            this.isLoading = true
            this.favGame.pageNo++
            const params = {
                pageNo: this.favGame.pageNo,
                pageSize: this.favGame.pageSize
            }
            getGameCollect(params).then((res) => {
                const {
                    result,
                    code
                } = res
                if (code) return
                const {
                    list,
                    total
                } = result
                this.favGame.favList = [...this.favGame.favList, ...list]
                this.favGame.hasMore = Number(total) > this.favGame.favList.length
                this.favGame.isLoading = false
            })
            this.firstLoaded = false
        },
        getGameFavs() {
            getGameAllCollect().then((res) => {
                if (res.code) return
                this.activeIds = res.result
            })
        },
        async addCollect(item) {
            if (!this.isDebounced) {
                this.isDebounced = true
                const that = this
                if (that.activeIds.includes(item.id)) {
                    const index = that.activeIds.indexOf(item.id)
                    if (index !== -1) {
                        await removeFav({
                            id: item.id
                        })
                            .then((res) => {
                                if (res.code === 0) {
                                    that.activeIds.splice(index, 1)
                                    Toast(that.$t('home.CollectionCanceled'))
                                }
                            })
                    }
                } else {
                    await addFav({
                        id: item.id
                    })
                        .then((res) => {
                            if (res.code === 0) {
                                that.activeIds.push(item.id)
                                Toast(that.$t('home.CollectionSucc'))
                            }
                        })
                }
                setTimeout(() => {
                    this.isDebounced = false
                }, 500)
            } else {
                console.log('111')
            }
        },
        openGamePop(item) {
            if (!this.classObj.mobile) {
                this.isShowGamePop = false
            } else {
                this.gamePopInfo = item
                this.isShowGamePop = true
            }
        },
        onShowGamePopup(item) {
            this.onNavToByIsLogin({}).then(() => {
                this.gameParams = {
                    id: item.gameId,
                    gameInfoId: item.id,
                    gamePlatformType: item.gamePlatformType,
                    providerType: item.providerType
                }
                this.$refs.gamePopup.open()
            })
        },
        copyText(content) {
            handleCopy(
                {
                    content,
                    success: (msg) => {
                        Toast(msg)
                    },
                    error: (msg) => {
                        Toast(msg)
                    }
                },
                this
            )
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {
        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
            }
        }

        .content {
            width: 100% !important;
            height: 100% !important;
        }

        .van-popup {
            .content {
                .header {
                    height: 1.12rem;
                    padding: 0 .3rem;
                    background: $bg-navbar-color;

                    &>div:first-child {
                        font-size: .32rem;
                    }

                    .close {
                        font-size: .32rem;
                    }
                }

                .user_content {
                    background: $bg-color;
                    flex: 1;
                    padding: .3rem .3rem;
                    display: flex;
                    flex-direction: column;
                    gap: .2rem;
                }

                .user_box {
                    position: relative;
                    background: $bg-box-color;
                    border-radius: .24rem;
                    display: flex;
                    flex-direction: row-reverse;
                    padding: .2rem .3rem .2rem .2rem;
                    column-gap: .2rem;

                    .vip_img {
                        position: static;
                        width: 1.4rem;
                        height: 1.4rem;
                    }

                    .user_info {
                        flex: 1;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        column-gap: .2rem;

                        .avatar {
                            width: 1.2rem;
                            height: 1.2rem;
                        }

                        .user {
                            text-align: left;

                            .phone {
                                font-size: .28rem;
                                margin-top: 0;
                            }

                            >div {
                                height: .34rem;
                                line-height: .34rem;
                                margin-top: .12rem;

                                &:last-child {
                                    i {
                                        font-size: .3rem;
                                        margin-left: .1rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .user_account {
                    display: flex;
                    background: $bg-box-color;
                    padding: .2rem 0;
                    gap: 0;

                    >div {
                        flex: 1;
                        background: transparent;
                        border-radius: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        row-gap: .08rem;
                        height: auto;

                        >div {
                            &:first-child {
                                font-size: .2rem;
                                // color: #516382;
                                line-height: .24rem;
                            }

                            &:last-child {
                                font-size: .24rem;
                                color: #ffffff;
                                line-height: .3rem;
                            }
                        }
                    }
                }

                .fav_games {
                    height: 6.42rem;
                    background: $bg-box-color;
                    padding: .2rem;
                    row-gap: .2rem;

                    .title {
                        text-align: left;
                    }

                    .game_list {
                        gap: .2rem;
                    }

                    .loading {
                        font-size: .28rem;
                    }

                    .empty {
                        font-size: .28rem;

                        .empty_img {
                            width: 3.4rem;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .user_content {
                flex: 1;
                // padding: .4rem .8rem .8rem;
                padding: 20px 40px 40px;
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .user_box {
                position: relative;

                .vip_img {
                    position: absolute;
                    top: -20px;
                    left: 0;
                    width: 80px;
                    height: 80px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .user_info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .avatar {
                        width: 80px;
                        height: 80px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            border: .04rem solid #D4C8C4;
                        }
                    }

                    .user {
                        .phone {
                            font-size: 18px;
                            margin-top: 10px;
                            color: #ffffff;
                        }

                        >div {
                            height: 20px;
                            line-height: 20px;
                            color: $font-sub-color;
                            margin-top: 6px;

                            &:last-child {
                                i {
                                    font-size: 20px;
                                    color: #e7d470;
                                    margin-left: 14px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

            .user_account {
                display: flex;
                gap: .1rem;

                >div {
                    flex: 1;
                    background: $bg-menu-color;
                    border-radius: .24rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    row-gap: 10px;
                    height: 72px;

                    >div {
                        &:first-child {
                            font-size: 14px;
                            color: $font-label-color;
                            line-height: 17px;
                        }

                        &:last-child {
                            font-size: 16px;
                            color: #ffffff;
                            line-height: 20px;
                        }
                    }
                }
            }

            .fav_games {
                // flex: 1;
                height: 321px;
                background: $bg-menu-color;
                border-radius: .24rem;
                padding: 20px;
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                position: relative;

                .title {
                    color: #ffffff;
                    text-align: left;
                }

                .game_list {
                    overflow-y: scroll;
                    height: 100%;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 10px;
                    position: relative;

                    .game_item {
                        width: 100%;
                        height: 2.68rem;
                        border-radius: 0.24rem;
                        overflow: hidden;
                        cursor: pointer;

                        &:hover {
                            .game_img {
                                .game_mask {
                                    display: block;
                                }
                            }
                        }

                        .game_img {
                            position: relative;
                            width: 100%;

                            img {
                                width: 100%;
                                height: 2.16rem;
                            }
                        }

                        .game_mask {
                            position: absolute;
                            background: rgba(0, 0, 0, .5);
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            z-index: 3;
                            --tw-backdrop-blur: blur(5px);
                            backdrop-filter: var(--tw-backdrop-blur);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            display: none;

                            .game_center {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                width: 100%;
                                height: 100%;

                                .name {
                                    color: #fff;
                                    font-size: .2rem;
                                    font-weight: bold;
                                    line-height: .25rem;
                                    height: .58rem;
                                    padding: .04rem 0;
                                }

                                .game_btn {
                                    background: $bg-btn-color;
                                    width: 78%;
                                    height: 16.88%;
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 20px;
                                    color: $font-btn-color;
                                    margin-top: .06rem;

                                    &>i {
                                        font-size: .24rem;
                                        margin-right: .1rem;
                                    }
                                }
                            }

                            .RTP {
                                padding-top: 5%;
                                font-size: .24rem;
                                margin: 0 4%;
                                color: $font-sub-color;
                                text-align: left;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;

                                &>span:last-child {
                                    background: linear-gradient(0deg, #FF940A 0%, #FAC988 100%);
                                    -webkit-background-clip: text;
                                    background-clip: text;
                                    color: transparent !important;
                                    margin-left: .08rem;
                                }
                            }

                            .game_collect {
                                position: absolute;
                                height: .5rem;
                                width: .5rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                z-index: 9;
                                right: .04rem;
                                bottom: .03rem;

                                &>i {
                                    font-size: .3rem;
                                    color: $font-sub-color;

                                    &.iconAct {
                                        color: #ec0048;
                                    }
                                }
                            }
                        }

                        .gameName {
                            height: .52rem;
                            line-height: .52rem;
                            text-align: center;
                            font-size: .2rem;
                            color: #ffffff;
                            background: #37221C;
                            padding: 0 .1rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    ::v-deep .van-loading {
                        display: none;
                    }
                }

                .loading {
                    color: $font-sub-color;
                    font-size: 14px;
                }

                .empty {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: $font-sub-color;
                    font-size: 16px;
                    text-align: center;

                    .empty_img {
                        width: 170px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
</style>
